import React from 'react'
import { Layout, SEO } from '../../../common/components'
import { NationalDayActivityBanner } from '../molecules/banner'
import {
  NationalDayContainer,
  NationalDayJoinWayContainer,
  NationalDayJoinWayContent,
  NationalDayJoinWayImage,
  NationalDayJoinWayInfo,
  NationalDayJoinWayTip,
  NationalDayJoinWayTitle,
  NationalDayRulesContainer,
  NationalDayTitle,
} from './xgt-101-recharge.atom'
import JoinWayImg from '../images/xgt-101-recharge/join-way.png'
import RulesImg from '../images/xgt-101-recharge/rules.png'
import AuthIconsImg from '../images/xgt-101-recharge/auth-icons.png'
import RechargeIconsImg from '../images/xgt-101-recharge/recharge-icons.png'

const RULES_LIST = [
  '1、 活动期间，用户在活动充值页充值200元起，享全额100%返渲染券！充200送200，充1000送1000，上不封顶，超大福利助力国庆！',
  '2、 用户参与活动完成充值后，赠送的渲染券直接到账，若30分钟未到账，请咨询效果图客服！',
  '3、 非效果图会员用户完成实名认证后，12小时内自动激活有效期为一个月效果图会员，若12小时内未激活，请咨询效果图客服！若用户原已是效果图会员，则赠送的一个月会员时长自动累积到会员剩余时长中；',
  '4、 活动赠送的渲染券不可申请发票、不可提现，此优惠不与其他充值优惠活动同享；',
  '5、 本次活动所充值金额仅限效果图渲染使用，不支持退款和提现；',
  '6、 如有任何问题，请咨询瑞云效果图客服，活动名额数量有限，先到先得！',
  '7、 本次活动最终解释权归瑞云科技所有，瑞云科技有权对恶意刷抢活动资源、利用活动资源从事违法违规行为的用户收回活动参与资格，并对涉事账号进行封禁处理！',
]
class XGTNationalDay extends React.PureComponent {
  render() {
    const { location } = this.props
    return (
      <Layout location={location}>
        <SEO
          title='瑞云渲染效果图国庆充值活动 - 瑞云渲染'
          keywords='效果图充值活动,渲染券充值活动,瑞云渲染充值活动'
          description='Renderbus瑞云渲染效果图国庆充值活动,充值返100%,实名再送会员.活动期间用户在活动充值页充值200元起,享全额100%返渲染券！充200送200,充1000送1000,超大福利助兴国庆！'
        />
        <NationalDayActivityBanner />
        <div style={{ backgroundColor: '#00685A' }}>
          <NationalDayContainer>
            <NationalDayTitle src={JoinWayImg} alt='参与方式' />
            <NationalDayJoinWayContainer>
              <NationalDayJoinWayContent>
                <NationalDayJoinWayTitle>福利一</NationalDayJoinWayTitle>
                <NationalDayJoinWayImage src={RechargeIconsImg} />
                <NationalDayJoinWayInfo>
                  <p>登录账户</p>
                  <p>充值</p>
                  <p>渲染券自动到账</p>
                  <NationalDayJoinWayTip>充值返100%</NationalDayJoinWayTip>
                </NationalDayJoinWayInfo>
              </NationalDayJoinWayContent>
              <NationalDayJoinWayContent>
                <NationalDayJoinWayTitle>福利二</NationalDayJoinWayTitle>
                <NationalDayJoinWayImage src={AuthIconsImg} />
                <NationalDayJoinWayInfo>
                  <p>登录账户</p>
                  <p>完成实名认证</p>
                  <p>
                    系统自动赠送
                    <br />
                    一个月会员
                  </p>
                  <NationalDayJoinWayTip>实名认证送会员</NationalDayJoinWayTip>
                </NationalDayJoinWayInfo>
              </NationalDayJoinWayContent>
            </NationalDayJoinWayContainer>
            <NationalDayTitle src={RulesImg} alt='活动规则' />
            <NationalDayRulesContainer>
              {RULES_LIST.map((rule, index) => (
                <li key={index}>{rule}</li>
              ))}
            </NationalDayRulesContainer>
          </NationalDayContainer>
        </div>
      </Layout>
    )
  }
}

export default XGTNationalDay
